.search-container {
    margin: auto;
    width: 100%;
    padding: 10px;
}

.search-button {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 50px;
}

.result-table-container {
    margin: auto;
    margin-top: 15px;
    max-height: 400px;
}

.loader {
    margin: auto;
    margin-top: 15px;
    text-align: center;
    font-size: large;
    font-weight: bold;
    font-style: oblique;
}

.summary-text {
    margin:auto;
    margin-top: 15px;
    font-size: 12px;
    text-align: center;
    width: 300px;
}

.box-shadow {
    box-shadow: 0 .25rem .75rem rgba(0, 0, 0, .05);
  }